import { extraCharges, transactionCategoryEnum } from "../constants/enums";
import { ILotSetting, IPaymentItem } from "../models/payment/PaymentInterface";

//Reusable Function to calculate Tax and SST
export const calculateTax = (
  sum: number,
  updatedItems: IPaymentItem[],
  feesList: ILotSetting[]
) => {
  const serviceChargePercentage = feesList.find(
    (fee) => fee.settingsName === extraCharges.serviceCharge
  );
  const SSTPercentage = feesList.find((fee) => fee.settingsName === "SST");

  const serviceCharge =
    (sum *
      parseFloat(
        serviceChargePercentage?.settingsDescription.replace("%", "")!
      )) /
    100;
  const sstCharge =
    (sum * parseFloat(SSTPercentage?.settingsDescription.replace("%", "")!)) /
    100;

  const formattedSST = roundUp(sstCharge, 2);
  const formattedService = roundUp(serviceCharge, 2);

  // const adjustmentSum = updatedItems
  //   .filter((item) => ["Adjustment"].includes(item.itemName))
  //   .reduce((acc, curr) => (acc = acc + curr.price), 0);

  // sum = sum + adjustmentSum;

  if (
    updatedItems.filter(
      (item) => item.itemType === serviceChargePercentage?.settingsCategory
    ).length === 0
  ) {
    sum = sum + formattedService;
    updatedItems.push({
      itemId: serviceChargePercentage?.settingsId!,
      itemName:
        serviceChargePercentage?.settingsName! +
        " " +
        serviceChargePercentage?.settingsDescription,
      itemType: serviceChargePercentage?.settingsCategory!,
      category: transactionCategoryEnum.serviceCharge,
      price: formattedService,
    });
  }

  if (
    updatedItems.filter(
      (item) => item.itemType === SSTPercentage?.settingsCategory
    ).length === 0
  ) {
    sum = sum + formattedSST;
    updatedItems.push({
      itemId: SSTPercentage?.settingsId!,
      itemName:
        SSTPercentage?.settingsName! + " " + SSTPercentage?.settingsDescription,
      itemType: SSTPercentage?.settingsCategory!,
      category: transactionCategoryEnum.tax,
      price: formattedSST,
    });
  }

  return { sum, updatedItems };
};

export const convertTo2Decimals = (value: number) => {
  return parseFloat(value.toFixed(2));
};

/**
 * Description convert number to currency format with 2 decimal points
 * @param value
 * @returns
 */

export function financeformat(value: number): {
  value: number;
  rounding: number;
} {
  const convertedValue = Number(value.toFixed(2));
  let basis = value.toString();
  if (basis.includes(".")) {
    const splittedBasis = basis.split(".");

    // take only 2 decimal points
    if (splittedBasis.length === 2) {
      basis = splittedBasis[0] + "." + splittedBasis[1].slice(0, 2);
    }
  }
  const rounding = parseFloat(basis) - convertedValue;

  return {
    value: convertedValue,
    rounding: Number.parseFloat(Math.abs(rounding).toFixed(2)),
  };
}

export function roundUp(number: number, decimalPlaces: number) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(number * factor) / factor;
}

export function roundingFunction(number: number) {
  const roundedValue = Math.round(number / 0.05) * 0.05;
  const roundingValue = Math.abs(number - roundedValue);
  return { roundedValue, roundingValue };
}
