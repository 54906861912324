import { FileDownload, FilterAlt, Flag } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ArrowDropDownIcon,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import XLSX from "sheetjs-style";
import { ReactComponent as ExcelIcon } from "../../assets/images/icons8-excel.svg";
import CustomTable from "../../components/global/table/CustomTable";
import axiosInstance from "../../constants/axiosConfig";
import {
  transactionCategoryEnum,
  zReportVersionEnum,
} from "../../constants/enums";
import {
  IDetailedZReport,
  ILot,
  IMAHBData,
  IMAHBSummaryData,
  IPaymentOverview,
  ISummaryZReport,
} from "../../models/payment/PaymentInterface";
import { RootState } from "../../redux/store";
import {
  convertArrayKeysToPascalCase,
  convertToReadableFormat,
  formatDocumentDateFormat,
  formatToPresentableNumber,
  getMonthsBetweenDates,
  isValidDate,
  stringDateToEpoch,
} from "../../utils/functions";
import Notification from "../../utils/notificationConfig";
import ZReportFiltersV2 from "../../components/z-report/ZReportFiltersV2";

const lotInitialValue: ILot = {
  airportCodeId: 0,
  lotDescription: "",
  lotId: 0,
  lotNumber: "",
};

const ZReportV2 = () => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isDownloadingExcel, setIsDownloadingExcel] = useState<boolean>(false);
  const [isReporting, setIsReporting] = useState<boolean>(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleCloseDropdown = () => setAnchorEl(null);
  const handleOpenDropdown = ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(currentTarget);
  };

  const today = new Date();

  const startOfDay = new Date(today);
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(today);
  endOfDay.setHours(23, 59, 59, 999);

  const currentDate = new Date();
  const currentMonthName = format(currentDate, "MMMM");

  const [reportType, setReportType] = useState<string>("KLIA/KLIA2");
  const [version, setVersion] = useState<string>(zReportVersionEnum.mahb);

  const [detailedData, setDetailedData] = useState<IDetailedZReport[]>([]);
  const [summaryData, setSummaryData] = useState<ISummaryZReport[]>([]);
  const [mahbData, setMAHBData] = useState<IMAHBData[]>([]);
  const [mahbSummaryData, setMAHBSummaryData] = useState<IMAHBSummaryData[]>(
    []
  );

  const [startDate, setStartDate] = useState<Date>(startOfDay);
  const [endDate, setEndDate] = useState<Date>(endOfDay);

  const [paymentOverview, setPaymentOverview] = useState<IPaymentOverview[]>(
    []
  );

  const [currentLot, setCurrentLot] = useState<ILot>(lotInitialValue);

  const totalDetailedSales = useMemo(
    () => detailedData.reduce((acc, curr) => acc + curr.sales, 0),
    [detailedData]
  );

  const totalSummaryRoomSales = useMemo(
    () => summaryData.reduce((acc, curr) => acc + curr.roomSales, 0),
    [summaryData]
  );

  const totalSummaryPOSSales = useMemo(
    () => summaryData.reduce((acc, curr) => acc + curr.posSales, 0),
    [summaryData]
  );

  const totalSummarySales = useMemo(
    () => summaryData.reduce((acc, curr) => acc + curr.total, 0),
    [summaryData]
  );

  const totalMAHBSummarySales = useMemo(
    () => mahbSummaryData.reduce((acc, curr) => acc + curr.total, 0),
    [mahbSummaryData]
  );

  // ----------------------Un-comment Once API is Done------------------------------------------
  const fetchTransactions = () => {
    setIsLoading(true);

    const endDateTime = endDate;

    endDateTime.setHours(23, 59, 59, 999);

    axiosInstance
      .get(`/transaction/get-z-report`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          // startDateTime: startDate
          //   ? stringDateToEpoch(startDate.toString())
          //   : "",
          // endDateTime: endDate ? stringDateToEpoch(endDateTime.toString()) : "",
          startDateTime: startDate ? startDate : "",
          endDateTime: endDate ? endDateTime : "",
          reportType: reportType,
          versions: version,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const dataList = res.data.data;
          if (version === zReportVersionEnum.detailed) {
            setDetailedData(
              dataList.map((data: any) => ({
                ...data,
                sales: parseFloat(data.sales),
                paymentDate: isValidDate(data.paymentDate)
                  ? format(new Date(data.paymentDate), "dd/MM/yyyy")
                  : data.paymentDate,
                checkIn: isValidDate(data.checkIn)
                  ? format(new Date(data.checkIn), "dd/MM/yyyy")
                  : data.checkIn,
                checkOut: isValidDate(data.checkOut)
                  ? format(new Date(data.checkOut), "dd/MM/yyyy")
                  : data.checkOut,
              }))
            );
            setSummaryData([]);
            setMAHBData([]);
            setMAHBSummaryData([]);
          }
          if (version === zReportVersionEnum.summary) {
            setSummaryData(
              dataList.map((data: any) => ({
                ...data,
                roomSales: parseFloat(data.roomSales),
                posSales: parseFloat(data.posSales),
                total: parseFloat(data.total),
              }))
            );
            setDetailedData([]);
            setMAHBData([]);
            setMAHBSummaryData([]);
          }
          if (version === zReportVersionEnum.mahb) {
            setMAHBData(
              dataList.map((data: IMAHBData) => ({
                ...data,
                transactionDate: format(
                  new Date(data.transactionDate),
                  "yyyy-MM-dd HH:mm:ss"
                ),
              }))
            );
            setDetailedData([]);
            setSummaryData([]);
            setMAHBSummaryData([]);
          }
          if (version === zReportVersionEnum.mahbSummary) {
            setMAHBSummaryData(dataList);
            setMAHBData([]);
            setDetailedData([]);
            setSummaryData([]);
          }
        }
      })
      .catch((e) => {
        Notification.failed("something wrong, please try again");
      })
      .finally(() => setIsLoading(false));
  };

  const fetchOverviewTransactions = () => {
    axiosInstance
      .get(`/transaction/report-overview`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDateTime: startDate
            ? stringDateToEpoch(startDate.toString())
            : "",
          endDateTime: endDate ? stringDateToEpoch(endDate.toString()) : "",
          reportType: reportType,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setPaymentOverview(res.data.data);
        }
      });
  };

  useEffect(() => {
    fetchTransactions();
    fetchOverviewTransactions();
  }, [startDate, endDate, reportType, version]);

  const fetchCurrentLot = () => {
    axiosInstance
      .get(`/lot/get-own-lot`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setCurrentLot(res.data);
          return;
        }
        Notification.failed(res.data.msg);
      })
      .catch((e) => {
        Notification.failed(e.response.data.msg);
      });
  };

  useEffect(() => {
    fetchCurrentLot();
  }, []);

  const detailedDataColumns = useMemo<MRT_ColumnDef<IDetailedZReport>[]>(
    () => [
      {
        header: "Item",
        accessorKey: "item",
      },

      {
        header: "Type",
        Cell: ({ row }) => <Typography>{row.original.type}</Typography>,
      },
      {
        header: "ID",
        Cell: ({ row }) => <Typography>{row.original.id}</Typography>,
      },

      {
        header: "Details",
        size: 200,
        Cell: ({ row }) => (
          <Stack direction={"column"}>
            {row.original.details && row.original.details.length > 0
              ? row.original.details
                  .filter(
                    (item) =>
                      item.category !== transactionCategoryEnum.serviceCharge &&
                      item.category !== transactionCategoryEnum.tax &&
                      item.category !== transactionCategoryEnum.promotion &&
                      item.category !== transactionCategoryEnum.rounding &&
                      item.category !== transactionCategoryEnum.adjustmentSales
                  )
                  .map((item) => (
                    <Typography>{`${item.quantity ? item.quantity + "x" : ""} ${
                      item.itemName
                    } ${
                      item.itemType && item.itemType !== "Merch"
                        ? item.itemType
                        : ""
                    }`}</Typography>
                  ))
              : ""}
          </Stack>
        ),
      },
      {
        header: "Payment Date",
        Cell: ({ row }) => <Typography>{row.original.paymentDate}</Typography>,
      },
      {
        header: "Check-In",
        Cell: ({ row }) => <Typography>{row.original.checkIn}</Typography>,
      },
      {
        header: "Check-Out",
        Cell: ({ row }) => <Typography>{row.original.checkOut}</Typography>,
      },
      {
        header: "Sales(RM)",
        Cell: ({ row }) => (
          <Typography>
            {row.original.sales
              ? formatToPresentableNumber(row.original.sales.toString())
              : ""}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Sales:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalDetailedSales?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
    ],
    [totalDetailedSales]
  );

  const summaryDataColumns = useMemo<MRT_ColumnDef<ISummaryZReport>[]>(
    () => [
      {
        header: "Date",
        Cell: ({ row }) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"200px"}
            height={"30px"}
            bgcolor={"black"}
            color={theme.palette.primary.main}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.primary.main}
          >
            {row.original.date}
          </Box>
        ),
      },
      {
        header: "Room Sales",
        Cell: ({ row }) => (
          <Typography>
            {row.original.roomSales
              ? formatToPresentableNumber(row.original.roomSales.toString())
              : ""}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Room Sales:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSummaryRoomSales?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "POS",
        Cell: ({ row }) => (
          <Typography>
            {row.original.posSales
              ? formatToPresentableNumber(row.original.posSales.toString())
              : ""}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total POS Sales:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSummaryPOSSales?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Total",
        Cell: ({ row }) => (
          <Typography>
            {row.original.total
              ? formatToPresentableNumber(row.original.total.toString())
              : ""}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Transactions:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSummarySales?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
    ],
    [totalSummaryPOSSales, totalSummaryRoomSales, totalSummarySales]
  );

  const mahbDataColumns = useMemo<MRT_ColumnDef<IMAHBData>[]>(
    () => [
      {
        header: "Store",
        accessorKey: "store",
        Cell(props) {
          return <Typography>{props.row.original.store}</Typography>;
        },
      },
      {
        header: "Invoice No",
        accessorKey: "transactionId",
        Cell(props) {
          return <Typography>{props.row.original.transactionId}</Typography>;
        },
      },
      {
        header: "Type",
        accessorKey: "transactionType",
        Cell(props) {
          return <Typography>{props.row.original.transactionType}</Typography>;
        },
      },
      {
        header: "Status",
        accessorKey: "transactionStatus",
        Cell(props) {
          return (
            <Typography>{props.row.original.transactionStatus}</Typography>
          );
        },
      },
      {
        header: "Date",
        accessorKey: "transactionDate",
        Cell(props) {
          return <Typography>{props.row.original.transactionDate}</Typography>;
        },
      },
      {
        header: "Total Amount",
        accessorKey: "transactionTotalAmount",
        Cell(props) {
          return (
            <Typography>{props.row.original.transactionTotalAmount}</Typography>
          );
        },
      },
      {
        header: "Service Charge",
        accessorKey: "transactionServiceChargeAmount",
        Cell(props) {
          return (
            <Typography>
              {props.row.original.transactionServiceChargeAmount}
            </Typography>
          );
        },
      },
      {
        header: "Rounding",
        accessorKey: "transactionRoundingAmount",
        Cell(props) {
          return (
            <Typography>
              {props.row.original.transactionRoundingAmount}
            </Typography>
          );
        },
      },
    ],
    []
  );

  const mahbSummaryDataColumns = useMemo<MRT_ColumnDef<IMAHBSummaryData>[]>(
    () => [
      {
        header: "Date",
        Cell: ({ row }) => (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"200px"}
            height={"30px"}
            bgcolor={"black"}
            color={theme.palette.primary.main}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.primary.main}
          >
            {row.original.transactionDate}
          </Box>
        ),
      },
      {
        header: "Total",
        accessorKey: "total",
        Cell(props) {
          return (
            <Typography>
              {formatToPresentableNumber(props.row.original.total.toString())}
            </Typography>
          );
        },
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Before Tax:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalMAHBSummarySales?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
    ],
    [totalMAHBSummarySales]
  );

  const handleChangeReportType = (
    reportType: "KLIA/KLIA2" | "Gatewaymall"
  ): void => {
    setReportType(reportType);
    if (reportType === "KLIA/KLIA2") {
      setVersion(zReportVersionEnum.mahb);
    }
    if (reportType === "Gatewaymall") {
      setVersion(zReportVersionEnum.detailed);
    }
  };

  const handleChangeVersion = (
    version:
      | zReportVersionEnum.summary
      | zReportVersionEnum.detailed
      | zReportVersionEnum.mahb
      | zReportVersionEnum.mahbSummary
  ): void => {
    setVersion(version);
  };

  const handleStartDateChange = (startDate: Date) => {
    setStartDate(startDate);
  };

  const handleEndDateChange = (endDate: Date) => {
    endDate.setHours(23, 59, 59, 999);
    setEndDate(endDate);
  };

  const convertItemDetails = (unformattedDetailedData: IDetailedZReport[]) => {
    const footer = {
      item: "",
      type: "",
      id: "",
      details: "",
      paymentDate: "",
      checkIn: "",
      checkOut: "",
      sales: `Total Amount: ${totalDetailedSales.toFixed(2)}`,
    };
    const formattedDetailedData = unformattedDetailedData.map((data) => ({
      ...data,
      item: data.item.toString(),
      sales: data.sales.toString(),
      details: data.details
        .filter(
          (detail) =>
            detail.itemType !== "Promotion" &&
            detail.itemType !== "Tax" &&
            detail.itemType !== "Service Charge" &&
            detail.itemType !== "Rounding"
        )
        .map((detail) =>
          detail.quantity
            ? `${detail.quantity}x ${detail.itemName}`
            : detail.itemName
        )
        .join(", "),
    }));

    formattedDetailedData.push(footer);

    return formattedDetailedData;
  };

  const summaryAddFooter = (datas: ISummaryZReport[]) => {
    const footer = {
      item: "",
      date: "",
      roomSales: `Total Amount: ${totalSummaryRoomSales.toFixed(2)}`,
      posSales: `Total Amount: ${totalSummaryPOSSales.toFixed(2)}`,
      total: `Total Amount: ${totalSummarySales.toFixed(2)}`,
    };
    const formattedSalesData = datas.map((data) => ({
      ...data,
      item: data.item.toString(),
      roomSales: data.roomSales.toString(),
      posSales: data.posSales.toString(),
      total: data.total.toString(),
    }));

    formattedSalesData.push(footer);

    return formattedSalesData;
  };

  const mahbAddFooter = (datas: any) => {
    return datas;
  };

  const handleDownloadReport = () => {
    let dataReport: unknown = [];
    let apiUrl = `/transaction/generate-z-report/`;
    if (version === zReportVersionEnum.detailed) {
      dataReport = convertItemDetails(detailedData);
    }
    if (version === zReportVersionEnum.summary) {
      dataReport = summaryData;
      apiUrl = `/transaction/generate-z-report-txt/`;
    }

    if (version === zReportVersionEnum.mahb) {
      const convertedData = convertArrayKeysToPascalCase(mahbData);

      handleDownloadJSON(JSON.stringify(convertedData, null, 2));

      return;
    }

    setIsDownloading(true);

    const formData = {
      data: dataReport,
      startDate: format(startDate, "dd MMMM yyyy"),
      endDate: format(endDate, "dd MMMM yyyy"),
      version: version,
      lotId: currentLot.lotId,
    };

    axiosInstance
      .post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // Decode the base64 encoded PDF content
          const pdfContent = atob(res.data.pdf_base64);

          handleDownloadFile(pdfContent);
        }
      })
      .catch((res) => {
        Notification.failed(res.response.data.msg);
      })
      .finally(() => setIsDownloading(false));
  };

  const handleDownloadJSON = (data: string) => {
    // Create a blob from the data
    const blob = new Blob([data], { type: "application/json" });

    // Create a link element
    const link = document.createElement("a");

    // Set the download attribute with a filename
    link.download = "data.json";

    // Create a URL for the blob and set it as the href attribute
    link.href = window.URL.createObjectURL(blob);

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  const handleDownloadFile = (pdfContent: string, confirmPDF?: boolean) => {
    // Convert the decoded content to a Uint8Array
    const pdfData = new Uint8Array(pdfContent.length);
    for (let i = 0; i < pdfContent.length; i++) {
      pdfData[i] = pdfContent.charCodeAt(i);
    }

    const pdfBlob = new Blob([pdfData], { type: "application/pdf" });

    const textBlob = new Blob([pdfData], { type: "text/plain" });
    const url = URL.createObjectURL(
      version === zReportVersionEnum.detailed || confirmPDF ? pdfBlob : textBlob
    );
    const link = document.createElement("a");

    link.href = url;

    link.download = fileNaming(confirmPDF);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fileNaming = (confirmPDF?: boolean) => {
    let fileName = "";

    if (
      !currentLot.gateawaymallLot &&
      [zReportVersionEnum.detailed, zReportVersionEnum.summary].includes(
        version as zReportVersionEnum
      )
    ) {
      Notification.warning(
        "This lot does not required Gatewaymall Z report, naming of downloaded report will be slightly off"
      );
    }

    if (
      !currentLot.mahbLot &&
      [zReportVersionEnum.mahb].includes(version as zReportVersionEnum)
    ) {
      Notification.warning(
        "This lot does not required MAHB Z report, naming of downloaded report will be slightly off"
      );
    }

    if (version === zReportVersionEnum.detailed) {
      fileName = `${
        currentLot.gateawaymallLot ? currentLot.gateawaymallLot : ""
      }_${formatDocumentDateFormat(startDate)}_ZRPT.pdf`;
    }
    if (version === zReportVersionEnum.summary) {
      fileName = `${
        currentLot.gateawaymallLot ? currentLot.gateawaymallLot : ""
      }_${formatDocumentDateFormat(startDate)}_A.txt`;
    }

    if (version === zReportVersionEnum.mahb) {
      fileName = `${
        currentLot.mahbLot ? currentLot.mahbLot : ""
      }_${formatDocumentDateFormat(startDate)}_${
        currentLot.lotId === 2 ? "KLIA2" : "KLIA"
      }.json`;
    }

    return fileName;
  };

  function cleanObject(obj: any) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  const handleXLSXDownload = () => {
    const dataToExport =
      version === zReportVersionEnum.detailed
        ? detailedData.map((data) => ({
            ...data,
            details:
              data.details && data.details.length > 0
                ? data.details
                    .filter(
                      (item) =>
                        item.category !==
                          transactionCategoryEnum.serviceCharge &&
                        item.category !== transactionCategoryEnum.tax &&
                        item.category !== transactionCategoryEnum.promotion &&
                        item.category !== transactionCategoryEnum.rounding &&
                        item.category !==
                          transactionCategoryEnum.adjustmentSales
                    )
                    .map(
                      (item) =>
                        `${item.quantity ? item.quantity + "x " : ""}${
                          item.itemName
                        } ${
                          item.itemType && item.itemType !== "Merch"
                            ? item.itemType
                            : ""
                        }`
                    )
                    .join(", ")
                : "",
          }))
        : version === zReportVersionEnum.summary
        ? summaryData
        : mahbData;

    const companyNameHeader = `Company Name: ${currentLot.lotDescription}`;
    const reportNameHeader = `Report Name: ${convertToReadableFormat(
      reportType
    )} ${convertToReadableFormat(version)}`;
    const outletHeader = `Outlet: ${currentLot.lotNumber}`;
    const monthHeader = `Month: ${getMonthsBetweenDates(startDate, endDate)}`;
    const startAndEndDate = `Report Date from: ${format(
      startDate,
      "dd MMMM yyyy"
    )}        to: ${format(endDate, "dd MMMM yyyy")}`;
    const dateGenerated = `Date Generated: ${format(
      new Date(),
      "dd MMMM yyyy hh:mm"
    )}`;

    let Heading = [
      [companyNameHeader],
      [reportNameHeader],
      [outletHeader],
      [monthHeader],
      [startAndEndDate],
      [dateGenerated],
    ];

    // const ws = XLSX.utils.json_to_sheet(dataToExport);

    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.sheet_add_json(ws, dataToExport, {
      origin: "A8",
    });

    const borderStyle = {
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };

    ws["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: 7 } },
      { s: { r: 3, c: 0 }, e: { r: 3, c: 7 } },
      { s: { r: 4, c: 0 }, e: { r: 4, c: 7 } },
    ];

    Object.keys(ws).forEach((cell) => {
      if (cell !== "!ref") {
        ws[cell].s = borderStyle;
      }
    });

    ws["A1"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A2"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A3"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A4"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A5"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A6"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${fileNaming().split(".")[0]}.xlsx`);
  };

  return (
    <Box
      display={"flex"}
      bgcolor={theme.palette.background.default}
      maxWidth={"90vw"}
      border={2}
      borderColor={theme.palette.primary.main}
    >
      <Stack direction={"row"} width={"100%"}>
        <Stack
          direction={"column"}
          width={"18%"}
          borderRight={2}
          borderColor={theme.palette.primary.main}
        >
          <Box
            display={"flex"}
            bgcolor={theme.palette.background.default}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
            height={"100px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="h2">Overview</Typography>
          </Box>

          <Stack height={"100%"} spacing={1}>
            {paymentOverview.map((overview) => (
              <Box display={"flex"} flexDirection={"column"} padding={1}>
                <Box>
                  <Typography fontWeight={600}>
                    {overview.left.name} | {overview.right.name}
                  </Typography>
                  <Box
                    width={"100%"}
                    height={"30px"}
                    border={"2px solid white"}
                    display={"flex"}
                    marginTop={1}
                  >
                    <Box
                      width={"50%"}
                      display={"flex"}
                      alignItems={"center"}
                      sx={{
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        color={"black"}
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        fontWeight={600}
                      >
                        {overview.left.value}
                      </Typography>
                    </Box>
                    <Box
                      width={"50%"}
                      display={"flex"}
                      alignItems={"center"}
                      sx={{
                        backgroundColor: "black",
                      }}
                    >
                      <Typography
                        color={"white"}
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        fontWeight={600}
                      >
                        {overview.right.value}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Stack>
        </Stack>
        <Stack direction={"column"} width={"82%"}>
          <Box
            display={"flex"}
            width={"100%"}
            height={"100px"}
            justifyContent={"space-around"}
            alignItems={"center"}
            bgcolor={theme.palette.background.default}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <FilterAlt sx={{ fontSize: "32px" }} />
              <Typography variant="h3">Filter</Typography>
            </Stack>
            <ZReportFiltersV2
              anchorEl={anchorEl}
              reportType={reportType}
              version={version}
              // groupings={groupings}
              // groupingsList={groupingsList}
              // bookingStatusFilter={bookingStatusFilter}
              // setGroupingsList={setGroupingsList}
              handleChangeReportType={handleChangeReportType}
              handleChangeVersion={handleChangeVersion}
              handleCloseDropdown={handleCloseDropdown}
              // handleGroupingsChange={handleGroupingsChange}
              handleOpenDropdown={handleOpenDropdown}
              // handleChangeBookingStatusFilter={handleChangeBookingStatusFilter}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(e) => handleStartDateChange(e as Date)}
                  format="dd MMM yyyy"
                  slots={{
                    openPickerIcon: ArrowDropDownIcon,
                  }}
                  slotProps={{
                    layout: {
                      sx: {
                        ".MuiDateCalendar-root": {
                          color: theme.palette.primary.main,
                          borderRadius: 2,
                          borderWidth: 1,
                          borderColor: theme.palette.primary.main,
                          border: "1px solid",
                          backgroundColor: theme.palette.background.paper,
                        },
                        ".MuiButtonBase-root": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiTypography-root": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiPickersCalendarHeader-root": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiPickersMonth-monthButton": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiPickersCalendarHeader-label": {
                          color: theme.palette.primary.main,
                        },
                        ".Mui-selected": {
                          color: "black",
                        },
                        ".MuiPickersYear-yearButton": {
                          color: theme.palette.primary.main,
                        },
                      },
                    },
                    textField: {
                      variant: "filled",
                      inputProps: {
                        style: {
                          color: "white",
                          borderRadius: 1,
                          border: 2,
                        },
                      },
                      sx: {
                        width: "150px",
                        backgroundColor: "#232323",
                        border: "1px solid #148978",
                        borderRadius: 1,
                      },
                    },
                    openPickerButton: {
                      sx: {
                        color: "white",
                        marginTop: "1.8px",
                        zIndex: 1,
                      },
                    },
                    openPickerIcon: {
                      sx: {
                        width: "1em",
                        height: "1em",
                        zIndex: 1,
                      },
                    },
                  }}
                />
                <Typography variant="h3" marginX={1}>
                  -
                </Typography>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(e) => handleEndDateChange(e as Date)}
                  format="dd MMM yyyy"
                  slots={{
                    openPickerIcon: ArrowDropDownIcon,
                  }}
                  slotProps={{
                    layout: {
                      sx: {
                        ".MuiDateCalendar-root": {
                          color: theme.palette.primary.main,
                          borderRadius: 2,
                          borderWidth: 1,
                          borderColor: theme.palette.primary.main,
                          border: "1px solid",
                          backgroundColor: theme.palette.background.paper,
                        },
                        ".MuiButtonBase-root": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiTypography-root": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiPickersCalendarHeader-root": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiPickersMonth-monthButton": {
                          color: theme.palette.primary.main,
                        },
                        ".MuiPickersCalendarHeader-label": {
                          color: theme.palette.primary.main,
                        },
                        ".Mui-selected": {
                          color: "black",
                        },
                        ".MuiPickersYear-yearButton": {
                          color: theme.palette.primary.main,
                        },
                      },
                    },
                    textField: {
                      variant: "filled",
                      inputProps: {
                        style: {
                          color: "white",
                          borderRadius: 1,
                          border: 2,
                        },
                      },
                      sx: {
                        width: "150px",
                        backgroundColor: "#232323",
                        border: "1px solid #148978",
                        borderRadius: 1,
                      },
                    },
                    openPickerButton: {
                      sx: {
                        color: "white",
                        marginTop: "1.8px",
                        zIndex: 1,
                      },
                    },
                    openPickerIcon: {
                      sx: {
                        width: "1em",
                        height: "1em",
                        zIndex: 1,
                      },
                    },
                  }}
                />
              </Box>
              <Button
                id="file-download-button"
                variant="contained"
                disabled={isLoading}
                onClick={handleDownloadReport}
                sx={{ borderRadius: 1 }}
              >
                {isDownloading ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  <FileDownload />
                )}
              </Button>
              <Button
                variant="contained"
                disabled={isLoading || version === zReportVersionEnum.mahb}
                onClick={handleXLSXDownload}
                sx={{ borderRadius: 1 }}
              >
                {isDownloadingExcel ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  <ExcelIcon width={24} height={24} />
                )}
              </Button>
            </LocalizationProvider>
          </Box>
          {isLoading ? (
            <Box
              display={"flex"}
              height={"500px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box height={"100%"} width={"100%"}>
              {version === zReportVersionEnum.detailed ? (
                <CustomTable
                  key={zReportVersionEnum.detailed}
                  isLoading={isLoading}
                  columns={detailedDataColumns}
                  data={detailedData}
                  handlePaginationChange={setPagination}
                  pagination={pagination}
                  enableTopToolbar={false}
                  oddEvenBackground={true}
                />
              ) : version === zReportVersionEnum.summary ? (
                <CustomTable
                  key={zReportVersionEnum.summary}
                  isLoading={isLoading}
                  columns={summaryDataColumns}
                  data={summaryData}
                  handlePaginationChange={setPagination}
                  pagination={pagination}
                  enableTopToolbar={false}
                  oddEvenBackground={true}
                />
              ) : version === zReportVersionEnum.mahb ? (
                <CustomTable
                  key={"mahbData"}
                  isLoading={isLoading}
                  columns={mahbDataColumns}
                  data={mahbData}
                  handlePaginationChange={setPagination}
                  pagination={pagination}
                  enableTopToolbar={false}
                  oddEvenBackground={true}
                  renderDetailPanel={({
                    row: { original },
                  }: {
                    row: { original: IMAHBData };
                  }) => <ZReportV2MAHBDetail data={original} />}
                />
              ) : (
                <CustomTable
                  key={"mahbSummaryData"}
                  isLoading={isLoading}
                  columns={mahbSummaryDataColumns}
                  data={mahbSummaryData}
                  handlePaginationChange={setPagination}
                  pagination={pagination}
                  enableTopToolbar={false}
                  oddEvenBackground={true}
                />
              )}
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default ZReportV2;

const ZReportV2MAHBDetail = (props: { data: IMAHBData }) => (
  <Stack direction={"row"}>
    <Grid container maxWidth={"40vw"} rowSpacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="h4">Sales Item</Typography>
      </Grid>
      {props.data.salesItem.map((item, index) => (
        <Grid item key={index} xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography fontWeight={600}>Code: {item.itemCode}</Typography>
          <Typography>Name: {item.itemName}</Typography>
          <Typography>Qty: {item.soldQty}</Typography>
          <Typography>Unit Price: {item.unitPrice}</Typography>
          <Typography>Subtotal: {item.subTotal}</Typography>
          <Typography>Discount: {item.lineDiscountAmount}</Typography>
          <Typography>Tax Percentage: {item.lineTaxPercentage}</Typography>
          <Typography>Tax: {item.lineTaxAmount}</Typography>
        </Grid>
      ))}
    </Grid>
    <Stack>
      <Typography variant="h4" marginBottom={1}>
        Collection
      </Typography>
      {props.data.collection.map((item, index) => (
        <Box key={index}>
          <Typography>Method: {item.method}</Typography>
          <Typography>Type: {item.paymentType}</Typography>
          <Typography>Amount: {item.amount}</Typography>
        </Box>
      ))}
    </Stack>
  </Stack>
);
