import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { camelToNormal } from "../../utils/functions";
import { zReportVersionEnum } from "../../constants/enums";

const ZReportFiltersV2 = (props: {
  handleOpenDropdown: ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => void;
  handleCloseDropdown: VoidFunction;
  anchorEl: HTMLElement | null;
  reportType: string;
  handleChangeReportType: (reportType: "KLIA/KLIA2" | "Gatewaymall") => void;
  version: string;
  handleChangeVersion: (
    version:
      | zReportVersionEnum.summary
      | zReportVersionEnum.detailed
      | zReportVersionEnum.mahb
  ) => void;
}) => {
  return (
    <>
      <FormControl>
        <InputLabel variant="filled">Report Type</InputLabel>
        <Select
          variant="filled"
          value={"Report Type"}
          renderValue={(value) =>
            props.reportType ? camelToNormal(props.reportType) : value
          }
          SelectDisplayProps={{
            onClick: props.handleOpenDropdown,
          }}
          MenuProps={{
            keepMounted: true,
            sx: {
              ".MuiMenu-list": {
                color: "black",
              },
            },
          }}
          sx={{
            width: "150px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
        >
          <MenuItem
            value={"Report Type"}
            disabled
            sx={{
              display: "none",
            }}
          >
            Report Type
          </MenuItem>
          <MenuItem>
            <RadioGroup
              name={"report-type"}
              value={props.reportType}
              onChange={({ target: { value } }) =>
                props.handleChangeReportType(
                  value as "KLIA/KLIA2" | "Gatewaymall"
                )
              }
            >
              <FormControlLabel
                value="KLIA/KLIA2"
                control={<Radio />}
                label="KLIA/KLIA2"
              />
              <FormControlLabel
                value="Gatewaymall"
                control={<Radio />}
                label="Gatewaymall"
              />
            </RadioGroup>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel variant="filled">Versions</InputLabel>
        <Select
          variant="filled"
          value={"Versions"}
          renderValue={(value) =>
            props.version ? camelToNormal(props.version) : value
          }
          SelectDisplayProps={{
            onClick: props.handleOpenDropdown,
          }}
          MenuProps={{
            keepMounted: true,
            sx: {
              ".MuiMenu-list": {
                color: "black",
              },
            },
          }}
          sx={{
            width: "150px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
        >
          <MenuItem
            value={"Versions"}
            disabled
            sx={{
              display: "none",
            }}
          >
            Versions
          </MenuItem>
          <MenuItem>
            <RadioGroup
              name={"versions"}
              value={props.version}
              onChange={({ target: { value } }) =>
                props.handleChangeVersion(
                  value as
                    | zReportVersionEnum.summary
                    | zReportVersionEnum.detailed
                    | zReportVersionEnum.mahb
                )
              }
            >
              {props.reportType === "KLIA/KLIA2" ? (
                <>
                  <FormControlLabel
                    value={zReportVersionEnum.mahb}
                    control={<Radio />}
                    label="MAHB"
                  />
                  <FormControlLabel
                    value={zReportVersionEnum.mahbSummary}
                    control={<Radio />}
                    label="MAHB Summary"
                  />
                </>
              ) : (
                <>
                  <FormControlLabel
                    value={zReportVersionEnum.detailed}
                    control={<Radio />}
                    label="Detailed"
                  />
                  <FormControlLabel
                    value={zReportVersionEnum.summary}
                    control={<Radio />}
                    label="Summary"
                  />
                </>
              )}
            </RadioGroup>
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default ZReportFiltersV2;
